import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

import { Shop } from "Types";
import { useLanguage } from "Providers";

type Props = {
    restaurant: Shop;
};

const CheckoutHelmet: React.FC<Props> = ({ restaurant }) => {
    const { translate } = useLanguage();
    const location = useLocation();
    const url = `${window.location.origin}${location.pathname}`;

    return (
        <>
            {/** @ts-ignore */}
            <Helmet>
                <title>
                    {translate("checkoutHelmet")} | {restaurant.name} | Qopla
                </title>

                <meta
                    name="description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta
                    name="twitter:title"
                    content={`${restaurant.name} Beställ | ${restaurant.contactInformation.city} | qopla`}
                />
                <meta
                    name="twitter:description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta
                    property="og:title"
                    content={`${restaurant.name} Beställ | ${restaurant.contactInformation.city} | qopla`}
                />
                <meta
                    property="og:description"
                    content={`Beställ online med qopla från ${restaurant.name} ${restaurant.contactInformation.addressLine} i ${restaurant.contactInformation.city}`}
                />
                <meta property="og:image" content={restaurant.imageUrl} />
                <meta
                    name="robots"
                    content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
                />
                <link rel="canonical" href={url} />
            </Helmet>
        </>
    );
};

export default CheckoutHelmet;
