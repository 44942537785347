import React from "react";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";

import { ButtonProps, Flex } from "Atoms";
import { OnlineIconButton } from "OnlineComponents";

export const ExpressBackButton: React.FC<ButtonProps> = props => {
    return (
        <Flex
            align="center"
            position={{ lg: "sticky" }}
            marginRight={{ base: 0, lg: 8 }}
            marginBottom={{ base: 8, lg: 0 }}
        >
            <OnlineIconButton
                icon={FaArrowLeft}
                rounded="full"
                width={24}
                height={24}
                fontSize="4xl"
                type="button"
                _hover={{}}
                bg="newPrimaryFont"
                color="newPrimary"
                {...props}
            />
        </Flex>
    );
};
