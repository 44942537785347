import React from "react";
import { MdRestaurant } from "@react-icons/all-files/md/MdRestaurant";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";

import { Flex, Button, Box, Text } from "Atoms";
import { EatingPreference, useLanguage } from "Providers";
import { OrderWindow, OnlineEatingPreference } from "Types";
import { IconType } from "@react-icons/all-files";
import { useOrderWindowsStore } from "Stores";

type Props = {
    onEatingOptionClick: (eatingSelection: EatingPreference.EAT_HERE | EatingPreference.TAKE_AWAY) => void;
    setHeaderOpen: (value: React.SetStateAction<boolean>) => void;
    lightMode: boolean;
    eatingOptions: OnlineEatingPreference[];
    isPortrait: boolean;
};

export const ExpressEatingOptions: React.FC<Props> = ({
    onEatingOptionClick,
    setHeaderOpen,
    lightMode,
    eatingOptions,
    isPortrait
}) => {
    const {
        emptyCart,
        orderWindows: [orderWindow]
    } = useOrderWindowsStore();

    const { translate } = useLanguage();

    const onExpressEatingOptionClick = (
        eatingSelection: EatingPreference.EAT_HERE | EatingPreference.TAKE_AWAY,
        orderWindow: OrderWindow
    ) => {
        if (!!orderWindow?.cartProducts?.length) {
            emptyCart(orderWindow.id);
        }
        setHeaderOpen(open => !open);

        onEatingOptionClick(eatingSelection);
    };

    const getTitleKeyForEatingOption = (eatingPreference: EatingPreference): string => {
        switch (eatingPreference) {
            case EatingPreference.TAKE_AWAY:
                return "takeAway";
            case EatingPreference.EAT_HERE:
                return "eatHere";
            default:
                return "";
        }
    };

    const getIconForEatingOption = (eatingPreference: EatingPreference): IconType => {
        switch (eatingPreference) {
            case EatingPreference.TAKE_AWAY:
                return FaShoppingBag;
            case EatingPreference.EAT_HERE:
                return MdRestaurant;
            default:
                return MdRestaurant;
        }
    };
    const expressEatingOptions = eatingOptions
        .map(
            (option: string) =>
                (option === EatingPreference.EAT_HERE || option === EatingPreference.TAKE_AWAY) && {
                    titleKey: getTitleKeyForEatingOption(option),
                    onClick: () => onExpressEatingOptionClick(option, orderWindow),
                    icon: getIconForEatingOption(option)
                }
        )
        .filter(o => !!o);

    const isOnlyOneOption = expressEatingOptions.length === 1;

    return (
        <Flex
            direction={isPortrait ? "row" : "column"}
            justifyContent={isPortrait ? "space-between" : "space"}
            alignItems={"center"}
            width={isPortrait && !isOnlyOneOption ? { md: "470px", lg: "750px" } : "auto"}
        >
            {expressEatingOptions.map((eatOption: any) => (
                <Button
                    key={eatOption.titleKey}
                    cursor="pointer"
                    p={0}
                    width={isPortrait ? { md: "210px", lg: "340px" } : { lg: "210px", "2xl": "300px" }}
                    height={isPortrait ? { md: "90px", lg: "130px" } : { lg: "90px", "2xl": "120px" }}
                    fontSize={isPortrait ? { md: "1.5rem", lg: "2.5rem" } : { lg: "1.8rem", "2xl": "2.5rem" }}
                    borderRadius={"1rem"}
                    color={isPortrait ? "gray.800" : "white"}
                    backgroundColor={isPortrait ? "white" : "gray.800"}
                    _hover={undefined}
                    transition=""
                    mt={isPortrait ? 0 : { lg: 6, "2xl": 8 }}
                    onClick={eatOption.onClick}
                >
                    <Flex justifyContent={"space-around"} alignItems={"center"} width={"fit-content"}>
                        <Text mb={0} mr={4}>
                            {translate(eatOption.titleKey).toUpperCase()}
                        </Text>
                        <Box as={eatOption.icon} />
                    </Flex>
                </Button>
            ))}
        </Flex>
    );
};
