import React from "react";
import { Field } from "formik-next";
import { FaCreditCard } from "@react-icons/all-files/fa/FaCreditCard";
import { FaFileInvoiceDollar } from "@react-icons/all-files/fa/FaFileInvoiceDollar";

import { PaymentMethod as PaymentMethodType } from "Constants";
import {  SwishLogoPng } from "Assets";
import { Box, Flex, Header, Image, PseudoBox, Text } from "Atoms";
import { ILanguages, isEatingPreferenceCatering, Languagekey, useLanguage } from "Providers";
import { OnlineCheckoutFormValues } from "../OnlineCheckoutForm";
import { FormCheckBox, FormInput } from "Molecules";
import { OnlineCheckoutFormLabel } from "../OnlineCheckoutFormLabel";
import { OnlineCheckoutFormCard } from ".";
import { LanguageHeader } from "Components";
import { Settings, CustomerType, FoodOptions, EatingPreference } from "Types";
import { useQoplaStore } from "Stores";

type CFPaymentMethod = {
    text: string;
    value: PaymentMethodType;
    logo: any;
};

const multiLangPaymentMethods = {
    en: {
        invoice: "Invoice"
    },
    sv: {
        invoice: "Faktura"
    }
};

const getPaymentMethods = (
    { eatingPreference, deliveryInformation }: FoodOptions,
    acceptInvoicePayment: boolean,
    userLanguage: ILanguages,
    translate: (tid: Languagekey) => string
): CFPaymentMethod[] => {
    const invoiceText = multiLangPaymentMethods[userLanguage].invoice;

    let paymentMethods = [
        { text: "Swish", value: PaymentMethodType.SWISH, logo: SwishLogoPng },
        { text: translate("card"), value: PaymentMethodType.CARD, logo: FaCreditCard }
    ];

    if (
        acceptInvoicePayment &&
        isEatingPreferenceCatering(eatingPreference) &&
        deliveryInformation?.customerType === CustomerType.Organization
    ) {
        paymentMethods = paymentMethods.concat({
            text: invoiceText,
            value: PaymentMethodType.INVOICE,
            logo: FaFileInvoiceDollar
        });
    }

    return paymentMethods;
};

export const PaymentMethod: React.FC<{
    paymentMethod: CFPaymentMethod;
    paymentInformation: OnlineCheckoutFormValues["paymentInformation"];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    isDisabled?: boolean;
}> = ({ paymentMethod, setFieldValue, paymentInformation, isDisabled = false }) => {
    const { value, text, logo } = paymentMethod;
    const isSelected = paymentInformation.paymentMethod === value;
    const isCard = value === PaymentMethodType.CARD;
    return (
        <Box w="100%" mr={[0, 6]} mb={[6, 0]}>
            <PseudoBox
                as="button"
                type="button"
                display="flex"
                alignItems="center"
                justifyContent="center"
                transition="all 0.3s"
                p={5}
                boxShadow="md"
                borderWidth="1px"
                borderRadius="lg"
                width="100%"
                disabled={isDisabled}
                border={isSelected ? "3px solid #629fd9" : "3px solid white"}
                bg={isSelected ? "blue.100" : "white"}
                _hover={{ cursor: "pointer" }}
                _disabled={{
                    cursor: "not-allowed",
                    opacity: "45%"
                }}
                onClick={() => {
                    setFieldValue("paymentInformation.paymentMethod", value);
                }}
            >
                {value === PaymentMethodType.SWISH ? (
                    <Image src={SwishLogoPng} height="40px" width="40px" mr={2} />
                ) : (
                    <Box as={logo} size="40px" mr={2} />
                )}
                <Header fontSize="lg" mt="0" mb="0">
                    {text}
                </Header>
            </PseudoBox>
        </Box>
    );
};

type PaymentMethodFieldsProps = {
    setValues: (values: OnlineCheckoutFormValues, shouldValidate?: boolean | undefined) => void;
    values: OnlineCheckoutFormValues;
    paymentMethod: PaymentMethodType.SWISH | PaymentMethodType.INVOICE | PaymentMethodType.CARD;
    foodOptions: FoodOptions;
    translate: (tid: Languagekey) => string;
};

export const PaymentMethodFields: React.FC<PaymentMethodFieldsProps> = ({
    paymentMethod,
    setValues,
    values,
    foodOptions: { eatingPreference, deliveryInformation },
    translate
}) => {
    switch (paymentMethod) {
        case PaymentMethodType.SWISH:
            return <></>;
        case PaymentMethodType.CARD:
            return <Text fontSize="xl">{translate("enterCardDetailsInNextStep")}</Text>;
        case PaymentMethodType.INVOICE:
            return (
                <>
                    <Field
                        formLabel={translate("organizationNumber")}
                        placeholder={translate("organizationNumber")}
                        name="invoiceData.organisationNumber"
                        inputMode="numeric"
                        pattern="[0-9]*(.[0-9]+)?"
                        as={FormInput}
                        size="lg"
                        fullWidth
                        isMandatory
                    />
                    <Field
                        formLabel={translate("companyName")}
                        placeholder={translate("companyName")}
                        name="invoiceData.invoiceAddress.name"
                        as={FormInput}
                        size="lg"
                        fullWidth
                        isMandatory
                    />
                    {eatingPreference === EatingPreference.CATERING_DELIVERY &&
                        deliveryInformation?.customerType === CustomerType.Organization && (
                            <Field
                                id="tmpUseSameAsAbove"
                                formLabel={translate("useSameAsAbove")}
                                name="invoiceData.invoiceAddress.tmpUseSameAsAbove"
                                as={FormCheckBox}
                                onChange={() => {
                                    if (values.invoiceData.invoiceAddress.tmpUseSameAsAbove) {
                                        setValues({
                                            ...values,
                                            invoiceData: {
                                                ...values.invoiceData,
                                                invoiceAddress: {
                                                    ...values.invoiceData.invoiceAddress,
                                                    tmpUseSameAsAbove:
                                                        !values.invoiceData.invoiceAddress.tmpUseSameAsAbove,
                                                    addressLine: "",
                                                    postCode: "",
                                                    city: ""
                                                }
                                            }
                                        });
                                    }
                                }}
                            />
                        )}

                    {deliveryInformation?.customerType === CustomerType.Organization &&
                        !values.invoiceData.invoiceAddress.tmpUseSameAsAbove && (
                            <>
                                <Field
                                    formLabel={translate("address")}
                                    placeholder={translate("address")}
                                    name="invoiceData.invoiceAddress.addressLine"
                                    as={FormInput}
                                    size="lg"
                                    fullWidth
                                    isMandatory
                                />
                                <Field
                                    formLabel={translate("postCode")}
                                    placeholder={translate("postCode")}
                                    inputMode="numeric"
                                    pattern="[0-9]*(.[0-9]+)?"
                                    name="invoiceData.invoiceAddress.postCode"
                                    as={FormInput}
                                    size="lg"
                                    fullWidth
                                    isMandatory
                                />
                                <Field
                                    formLabel={translate("district")}
                                    placeholder={translate("district")}
                                    name="invoiceData.invoiceAddress.city"
                                    as={FormInput}
                                    size="lg"
                                    fullWidth
                                    isMandatory
                                />
                            </>
                        )}
                </>
            );

        default:
            return null;
    }
};

type Props = {
    foodOptions: FoodOptions;
    paymentInformation: OnlineCheckoutFormValues["paymentInformation"];
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    setValues: (values: OnlineCheckoutFormValues, shouldValidate?: boolean | undefined) => void;
    values: OnlineCheckoutFormValues;
    stepIndex?: number;
};

export const OnlineCheckoutPaymentCard: React.FC<Props> = props => {
    const { selectedShop: shop } = useQoplaStore();
    const settings = shop?.settings as Settings;
    const { userLanguage, translate } = useLanguage();

    const { foodOptions, stepIndex, ...rest } = props;

    const paymentMethods = getPaymentMethods(
        foodOptions,
        settings?.cateringSettings?.acceptInvoicePayment || false,
        userLanguage,
        translate
    );

    return (
        <OnlineCheckoutFormCard>
            <Flex height="inherit" marginBottom={6} align="center">
                <OnlineCheckoutFormLabel children={stepIndex} mr={4} />
                <LanguageHeader tid="paymentMethod" as="h2" lineHeight="1.2" size="lg" margin="0" />
            </Flex>
            {paymentMethods.length > 1 && (
                <Flex height="auto" direction={["column", "row"]} justify="center" mb={5}>
                    {paymentMethods.map(paymentMethod => (
                        <PaymentMethod key={paymentMethod.value} paymentMethod={paymentMethod} {...rest} />
                    ))}
                </Flex>
            )}
            <PaymentMethodFields
                paymentMethod={rest.paymentInformation.paymentMethod}
                foodOptions={foodOptions}
                translate={translate}
                {...rest}
            />
        </OnlineCheckoutFormCard>
    );
};
