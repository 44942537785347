import * as yup from "yup";

import { ILanguages } from "Providers";
import { PaymentMethod } from "Constants";
import { orgNoValidation } from "Utils";
import { validateLuhn } from "../../../admin/utils/YupValidationFunctions";

const multiLangValidation = {
    sv: {
        required: "* Obligatoriskt",
        onlyNumbers: "* Får bara innehålla siffror",
        phoneNumber: "* Kontrollera ditt telefonnummer( +46 7X XXX XX XX )",
        swishNumber: "*  Kontrollera ditt swishnummer( +46 7X XXX XX XX )",
        email: "* Måste vara en giltig email",
        accept: "* Fältet måste vara ikryssat",
        organisationNumber: "* Matchar inte svenskt org.nummer standard",
        minZero: "* Dricksen kan inte vara mindre än 0 kr"
    },
    en: {
        required: "* Required",
        onlyNumbers: "* May only contain numbers",
        phoneNumber: "* Check your phone number( +46 7X XXX XX XX )",
        swishNumber: "* Check your swish number( +46 7X XXX XX XX )",
        email: "* Must be a valid email",
        accept: "* The field must be checked",
        organisationNumber: "* Does not match Swedish corporate identity number standard",
        minZero: "* Tip cannot be less than 0 kr"
    }
};

export const getOnlineCheckoutValidation = (userLanguage: ILanguages, isHomeDelivery: boolean) => {
    const validationText = multiLangValidation[userLanguage];

    const isRequired = yup.string().required(validationText.required);

    const homeDeliveryValidationSchema = yup.object().shape({
        addressLine: isRequired,
        city: isRequired,
        lastName: isRequired
    });

    const onlineCheckoutValidationSchema = yup.object().shape({
        firstName: isRequired,
        email: isRequired.email(validationText.email),
        acceptTerms: yup.boolean().oneOf([true], validationText.accept),
        paymentInformation: yup.object().shape({
            paymentMethod: yup
                .string()
                .oneOf([PaymentMethod.SWISH, PaymentMethod.INVOICE, PaymentMethod.CARD], validationText.required)
        }),
        tip: yup.number().positive().min(0, validationText.minZero),
        invoiceData: yup.object().when("paymentInformation.paymentMethod", {
            is: val => val === PaymentMethod.INVOICE,
            then: yup.object().shape({
                organisationNumber: orgNoValidation("", userLanguage).test(
                    "organisationNumber",
                    validationText.organisationNumber,
                    async value => {
                        if (!!value && value.length > 9) {
                            return validateLuhn(value);
                        }
                        return true;
                    }
                ),
                invoiceAddress: yup.object().shape({
                    tmpUseSameAsAbove: yup.boolean().oneOf([true, false]),
                    name: isRequired,
                    addressLine: yup.string().when("tmpUseSameAsAbove", {
                        is: val => !val,
                        then: isRequired
                    }),
                    postCode: yup.number().when("tmpUseSameAsAbove", {
                        is: val => !val,
                        then: yup.number().typeError(validationText.onlyNumbers).required(validationText.required)
                    }),
                    city: yup.string().when("tmpUseSameAsAbove", {
                        is: val => !val,
                        then: isRequired
                    })
                })
            })
        })
    });

    return onlineCheckoutValidationSchema.concat((isHomeDelivery as any) && homeDeliveryValidationSchema);
};

export const getExpressOnlineCheckoutValidation = (userLanguage: ILanguages) => {
    const validationText = multiLangValidation[userLanguage];

    return yup.object().shape({
        tmpQr: yup.boolean().oneOf([true, false]),
        acceptTerms: yup.boolean().oneOf([true], validationText.accept)
    });
};
