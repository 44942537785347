import React, { useState } from "react";
import { IoMdPricetags } from "@react-icons/all-files/io/IoMdPricetags";
import { IoMdTrash } from "@react-icons/all-files/io/IoMdTrash";

import { BaseBoxProps, Box, Button, Flex, Input, Text } from "Atoms";
import { IconButton, InputGroup, InputRightElement } from "Molecules";
import { useLanguage } from "Providers";
import { DiscountMessage } from "../OnlineCheckoutForm";
import { Discount } from "Types";

type Props = {
    appliedDiscount: Discount | undefined;
    discountMessage: DiscountMessage | null;
    onRemoveDiscount: () => void;
    handleCheckDiscount: (discountCode: string) => Promise<void>;
} & BaseBoxProps;

export const DiscountInput: React.FC<Props> = ({
    appliedDiscount,
    discountMessage,
    onRemoveDiscount,
    handleCheckDiscount,
    ...rest
}) => {
    const [discountCode, setDiscountCode] = useState(appliedDiscount ? appliedDiscount.code : "");

    const { translate } = useLanguage();

    const onDiscountCodeChange = (event: any) => {
        setDiscountCode(event.target.value.toUpperCase());
    };

    return (
        <Box {...rest}>
            <Flex>
                <Box flex="1">
                    <InputGroup flex="1" size="lg">
                        <Input
                            pr="5rem"
                            mr={4}
                            autoComplete="off"
                            placeholder={translate("discountCode").toUpperCase()}
                            isInvalid={discountMessage ? !discountMessage.success && !!discountMessage.message : false}
                            value={discountCode}
                            onChange={onDiscountCodeChange}
                        />
                        <InputRightElement width="5rem">
                            <IconButton
                                size="sm"
                                w="2.5rem"
                                h="2.5rem"
                                icon={IoMdTrash}
                                variant="outline"
                                themeColor={discountCode ? "red" : "gray"}
                                isDisabled={!discountCode}
                                onClick={() => {
                                    onRemoveDiscount();
                                    setDiscountCode("");
                                }}
                            />
                        </InputRightElement>
                    </InputGroup>
                </Box>
                <Button
                    leftIcon={IoMdPricetags}
                    isDisabled={
                        !discountCode ? !discountCode : discountCode === appliedDiscount?.code && !!appliedDiscount
                    }
                    onClick={() => handleCheckDiscount(discountCode)}
                    h="3rem"
                >
                    {translate("use")}
                </Button>
            </Flex>
            {discountMessage && (
                <>
                    <Text
                        my="0.5em"
                        pl="0.5em"
                        fontWeight={600}
                        fontSize={"0.9em"}
                        color={discountMessage.success ? "green.500" : "red.500"}
                    >
                        {discountMessage.message}
                    </Text>
                    {discountMessage.onlineCustomerMessage && (
                        <Text pl="0.5em" fontWeight={600} wordBreak="break-word">
                            {translate("message")}: {discountMessage.onlineCustomerMessage}
                        </Text>
                    )}
                </>
            )}
        </Box>
    );
};
