import React, { useState } from "react";
import { Formik } from "formik-next";
import { motion, AnimatePresence } from "framer-motion";

import { Box, Flex, BaseBoxProps } from "Atoms";
import { useLanguage } from "Providers";
import { useOrientation } from "Hooks";
import { ExpressCheckoutOverview } from "./ExpressCheckoutOverview";
import { ExpressPaymentMethod } from "./ExpressPaymentMethod";
import { ExpressCheckoutFillPhoneNumber } from "./ExpressCheckoutFillPhoneNumber";
import { getExpressOnlineCheckoutValidation } from "../customerCheckoutValidation";
import { Discount } from "Types";

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

export const ExpressOnlineCheckoutContainer: React.FC<BaseBoxProps> = props => {
    return <Box width="100%" maxWidth="1080px" mx="auto" my="0" height="100%" p={4} userSelect="none" {...props} />;
};

export const ExpressOnlineWrapper: React.FC<BaseBoxProps> = props => {
    const { type } = useOrientation();
    const isPortrait = type === "portrait-secondary" || type === "portrait-primary";

    let styles = {};

    if (isPortrait) {
        styles = {
            mt: 48,
            justify: "baseline",
            height: "auto"
        };
    } else {
        styles = {
            justify: "center"
        };
    }

    return <Flex align="center" {...styles} {...props} />;
};

type Props = {
    setActiveOrderWindowDiscount: (discount: Discount | null, discountedProductIds: string[]) => void;
    onlineSettings: any;
    handleAddWebOrder: (values: any) => Promise<any>;
    shopUrl: string;
    shopId: string;
    eatingOptiong: string;
    eatingOptiongs: string[];
    ow: any;
    isCompanyLanding: boolean;
};

export const ExpressCheckoutForm: React.FC<Props> = ({
    setActiveOrderWindowDiscount,
    handleAddWebOrder,
    onlineSettings,
    shopUrl,
    shopId,
    eatingOptiong,
    eatingOptiongs,
    ow,
    isCompanyLanding
}) => {
    const { userLanguage } = useLanguage();

    const initialValues = {
        swishNumber: "",
        phoneNumber: "",
        comment: "",
        tmpQr: true
    };

    const [[page, direction], setPage] = useState([0, 0]);

    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection]);
    };

    return (
        <Box backgroundColor="gray.100" height="100%" overflow="hidden">
            <Box position="relative" w="100vw" h="100vh">
                <Formik
                    validationSchema={getExpressOnlineCheckoutValidation(userLanguage)}
                    initialValues={initialValues}
                    onSubmit={async (values, { setSubmitting }) => {
                        // @ts-ignore
                        const res: any = await handleAddWebOrder(values);
                        if (!!res) {
                            setSubmitting(false);
                        }
                    }}
                >
                    {formikProps => {
                        const pages = [
                            <ExpressCheckoutOverview
                                setActiveOrderWindowDiscount={setActiveOrderWindowDiscount}
                                cartProducts={ow.cartProducts}
                                appliedDiscount={ow.discount}
                                onlineSettings={onlineSettings}
                                shopUrl={shopUrl}
                                shopId={shopId}
                                eatingOption={eatingOptiong}
                                eatingOptions={eatingOptiongs}
                                paginate={paginate}
                                isCompanyLanding={isCompanyLanding}
                            />,
                            <ExpressPaymentMethod formikProps={formikProps} paginate={paginate} />,
                            <ExpressCheckoutFillPhoneNumber paginate={paginate} formikProps={formikProps} />
                        ];

                        return (
                            <AnimatePresence initial={false} custom={direction}>
                                <motion.div
                                    key={page}
                                    custom={direction}
                                    variants={variants}
                                    initial="enter"
                                    animate="center"
                                    exit="exit"
                                    transition={{
                                        x: { type: "spring", stiffness: 300, damping: 30 },
                                        opacity: { duration: 0.2 }
                                    }}
                                >
                                    <Box position="absolute" h="100vh" w="100vw">
                                        {pages[page]}
                                    </Box>
                                </motion.div>
                            </AnimatePresence>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
