import React from "react";
import { RiErrorWarningLine } from "@react-icons/all-files/ri/RiErrorWarningLine";

import { Text, Box, Stack } from "Atoms";
import { useLanguage } from "Providers";

export const ExpressCheckoutDisruption: React.FC<{}> = ({}) => {
    const { translate } = useLanguage();

    return (
        <Stack stretch={2} justify="center" isInline align="center" mt={2} mb={2}>
            <Box as={RiErrorWarningLine} color="red.500" size={5} />
            <Text color="red.500" as="span">
                {translate("swishServiceIsDisrupted")}
            </Text>
        </Stack>
    );
};
