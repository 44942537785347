import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-apollo";

import { GET_SHOP_ONLINE_SETTINGS } from "GraphQLQueries";
import { Flex, Spinner } from "Atoms";

type QueryVars = {
    publicId: String;
};

type Params = { id: string; name: string };

export const OnlineRedirect: React.FC<{}> = ({}) => {
    const params = useParams<Params>();
    const navigate = useNavigate();
    const { data, loading } = useQuery<any, QueryVars>(GET_SHOP_ONLINE_SETTINGS, {
        variables: {
            publicId: params.id!
        }
    });
    useEffect(() => {
        if (!loading) {
            if (Boolean(data) && Boolean(data.getPublicShopById)) {
                const {
                    getPublicShopById: {
                        settings: { onlineSettings }
                    }
                } = data;

                if (onlineSettings.useHomePage) {
                    return navigate(`/restaurant/${params.name}/${params.id}/home`);
                } else {
                    return navigate(`/restaurant/${params.name}/${params.id}/order`);
                }
            } else {
                return navigate("/notFound", {
                    state: {
                        from: `/restaurant/${params.name}/${params.id}`,
                        redirectReasons: {
                            data
                        }
                    }
                });
            }
        }
    }, [loading]);

    return (
        <Flex justify="center" align="center">
            <Spinner />
        </Flex>
    );
};
