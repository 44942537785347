import React from "react";

import { Flex, Button, Box } from "Atoms";
import { LanguageHeader } from "Components";
import { useLanguage } from "Providers";
import { CheckoutFormCard } from "./CheckoutFormCard";
import { ExpressBackButton } from "./ExpressBackButton";
import { ExpressOnlineCheckoutContainer, ExpressOnlineWrapper } from "./ExpressCheckoutForm";
import { PhoneInputWithCountryCode } from "../../../shared/components";

type Props = {
    paginate: (page: number) => void;
    formikProps: any;
};

export const ExpressCheckoutFillPhoneNumber: React.FC<Props> = ({ paginate, formikProps }) => {
    const { translate } = useLanguage();

    return (
        <ExpressOnlineCheckoutContainer maxWidth="800px">
            <ExpressOnlineWrapper>
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    align="center"
                    justify="center"
                    height="auto"
                    width="100%"
                >
                    <ExpressBackButton
                        onClick={() => {
                            formikProps.setValues({
                                comment: formikProps.values.comment,
                                tmpQr: true,
                                phoneNumber: "",
                                swishNumber: ""
                            });
                            paginate(-1);
                        }}
                    />
                    <Flex direction="column" width="100%">
                        <CheckoutFormCard height="3xs" display="flex" flexDirection="column">
                            <Box flex="1">
                                <Box mb={4}>
                                    <LanguageHeader tid="yourSwishNumber" size="2xl" />
                                </Box>
                                <PhoneInputWithCountryCode
                                    phoneNumber={formikProps.values.swishNumber}
                                    name="swishNumber"
                                />
                            </Box>
                            <Button
                                themeColor="blue"
                                type="submit"
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={!formikProps.isValid}
                                fullWidth
                                size="xl"
                            >
                                {translate("pay")}
                            </Button>
                        </CheckoutFormCard>
                    </Flex>
                </Flex>
            </ExpressOnlineWrapper>
        </ExpressOnlineCheckoutContainer>
    );
};
