import React from "react";
import { RiQrScanLine } from "@react-icons/all-files/ri/RiQrScanLine";
import { MdPhoneIphone } from "@react-icons/all-files/md/MdPhoneIphone";
import { IconType } from "@react-icons/all-files";

import { Flex, Box, NewButton } from "Atoms";
import { CheckoutFormCard } from "./CheckoutFormCard";
import { ExpressBackButton } from "./ExpressBackButton";
import { ExpressOnlineCheckoutContainer, ExpressOnlineWrapper } from "./ExpressCheckoutForm";
import { LanguageHeader, LanguageText } from "Components";

type Props = {
    paginate: (page: number) => void;
    formikProps: any;
};

type ExpressPaymentOption = {
    icon: IconType;
    titleKey: string;
    type: "button" | "submit" | "reset" | undefined;
    onClick: (e: any) => void;
};

const ExpressPaymentMethodCard: React.FC<{ option: ExpressPaymentOption }> = ({ option }) => {
    return (
        <NewButton
            height="300px"
            width="300px"
            borderStyle="solid"
            mb={{ base: 8, lg: 0 }}
            boxShadow="lg"
            rounded="lg"
            alignItems="center"
            borderWidth={1}
            borderRadius={8}
            bg="white"
            type={option.type}
            onClick={option.onClick}
            _hover={{}}
            borderColor="gray.300"
        >
            <Flex direction="column" justify="center" align="center">
                <Box size="170px" as={option.icon} mb={4} color="newPrimary" />
                <LanguageText tid={option.titleKey} fontWeight="bold" fontSize="2xl" />
            </Flex>
        </NewButton>
    );
};

export const ExpressPaymentMethod: React.FC<Props> = ({ paginate, formikProps }) => {
    const options = [
        {
            icon: RiQrScanLine,
            titleKey: "scanQR",
            type: "submit",
            onClick: (e: any) => {
                e.preventDefault();
                formikProps.handleSubmit();
            }
        },
        {
            icon: MdPhoneIphone,
            titleKey: "enterPhoneNumber",
            type: "button",
            onClick: (e: any) => {
                formikProps.setFieldValue("tmpQr", false);
                paginate(1);
            }
        }
    ] as ExpressPaymentOption[];

    return (
        <ExpressOnlineCheckoutContainer>
            <ExpressOnlineWrapper>
                <Flex
                    direction={{ base: "column", lg: "row" }}
                    align="center"
                    justify="center"
                    height="auto"
                    width="100%"
                    padding={{ base: 4, lg: 8 }}
                >
                    <ExpressBackButton onClick={() => paginate(-1)} />
                    <Flex direction="column" justify="center" width="100%">
                        <CheckoutFormCard height="min-content" textAlign={{ sm: "center", lg: "unset" }}>
                            <Box mb={6}>
                                <LanguageHeader tid="choosePaymentMethod" size="2xl" mb={1} />
                                <LanguageText tid="receiveSmsForCollection" fontSize="2xl" color="gray.700" />
                            </Box>
                            <Flex
                                direction={{ base: "column", lg: "row" }}
                                p={4}
                                height="auto"
                                justify="space-between"
                                alignItems={{ sm: "center", lg: "unset" }}
                            >
                                {options.map(option => (
                                    <ExpressPaymentMethodCard key={option.titleKey} option={option} />
                                ))}
                            </Flex>
                        </CheckoutFormCard>
                    </Flex>
                </Flex>
            </ExpressOnlineWrapper>
        </ExpressOnlineCheckoutContainer>
    );
};
