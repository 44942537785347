import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useMutation } from "react-apollo";
import { Field } from "formik-next";
import moment from "moment";

import { Flex, Header, Button, NewDivider, List, ListItem, Text, Textarea } from "Atoms";
import { useOnline, usePos, useLanguage, EatingPreference } from "Providers";
import { getCartTotalPrice } from "Utils";
import { LanguageHeader } from "Components";
import { formatSwedishFinancialNumbers, roundNumber } from "TempUtils";
import { OnlineCustomerCartList, OnlineText } from "OnlineComponents";
import {
    getCartTotalNetPrice,
    getCartTotalOriginalPrice
} from "../../../../admin/components/poses/pos/utils/priceUtils";
import { VALIDATE_ONLINE_DISCOUNT_CODE } from "../../../../graphql/mutations/DiscountMutations";
import { Discount } from "Types";
import { calculateOrderWindowTotalDiscount } from "../../../../admin/components/poses/pos/utils/paymentHelpers";
import { CheckoutFormCard } from "./CheckoutFormCard";
import { ExpressBackButton } from "./ExpressBackButton";
import { ExpressOnlineCheckoutContainer, ExpressOnlineWrapper } from "./ExpressCheckoutForm";
import { LOCAL_STORAGE_CONSTANTS, timeConstants } from "Constants";
import { DiscountInput } from "./OnlineCheckoutForm/components";
import { ExpressOnlinePickupButton } from "../../expressOnlineOrder/components";
import { ExpressCheckoutDisruption } from "./ExpressCheckoutDisruption";
import { getDiscountedCartProductIds, removeBannedDiscountedProductIds } from "OnlineUtils";
import { useOnlineStore } from "Stores";

type ValidateDiscount = {
    validateOnlineDiscountCode: Discount;
};

type DiscountMessage = {
    success: boolean;
    message: string;
    onlineCustomerMessage: string;
};

type Props = {
    setActiveOrderWindowDiscount: (discount: Discount | null, discountedProductIds: string[]) => void;
    cartProducts: any[];
    appliedDiscount?: Discount;
    onlineSettings: any;
    shopUrl: string;
    shopId: string;
    eatingOption: string;
    eatingOptions: string[];
    paginate: (page: number) => void;
    isCompanyLanding?: boolean;
};

export const ExpressCheckoutOverview: React.FC<Props> = ({
    cartProducts,
    appliedDiscount,
    onlineSettings,
    shopUrl,
    shopId,
    eatingOption,
    eatingOptions,
    paginate,
    isCompanyLanding
}) => {
    const { translate } = useLanguage();
    const {
        foodOptions: { eatingPreference },
        serviceDisruptions: { isSwishServiceDisrupted },
        handleSetFoodOptions
    } = useOnline();
    const {
        discountUtils: { setActiveOrderWindowDiscount }
    } = usePos();
    const { getAlcoholRefProductIds } = useOnlineStore();

    const expressOnlineDisplaySettings = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_CONSTANTS.EXPRESS_ONLINE_DISPLAY_SETTINGS) || ""
    );

    const [discountMessage, setDiscountMessage] = useState<DiscountMessage | null>(null);

    const isTakeAway = eatingPreference === EatingPreference.TAKE_AWAY;

    const [validateDiscountCode] = useMutation<ValidateDiscount>(VALIDATE_ONLINE_DISCOUNT_CODE);

    const subTotal = roundNumber(getCartTotalPrice(cartProducts));
    const totalPrice = subTotal;
    const totalNetPrice = roundNumber(getCartTotalNetPrice(cartProducts));
    const vatAmount = (totalPrice - totalNetPrice).toFixed(2);
    const hasProducts = !!cartProducts?.length;
    const cartProductQuantity = cartProducts.reduce((acc, product) => acc + product.orderProduct.quantity, 0);

    const hasSwishDisrupted = isSwishServiceDisrupted && hasProducts;

    const { commentToClient, disableShopComments, commentPlaceholder } = onlineSettings;

    const formValidateDiscountCode = async (discountCode: string) => {
        const { data } = await validateDiscountCode({
            variables: { discountCode, shopId, eatingOption }
        });
        if (data && data.validateOnlineDiscountCode) {
            const discount = data.validateOnlineDiscountCode;
            const discountedCartProductIds = getDiscountedCartProductIds(cartProducts, discount);

            const alcoholRefProductIds = getAlcoholRefProductIds();
            const discountIdsWithRemovedAlcoholIds = removeBannedDiscountedProductIds(
                cartProducts,
                alcoholRefProductIds,
                discountedCartProductIds
            );

            if (discountIdsWithRemovedAlcoholIds.length) {
                setActiveOrderWindowDiscount(discount, discountIdsWithRemovedAlcoholIds);
                setDiscountMessage({
                    success: true,
                    message: `${translate("theDiscountCode")} ${discountCode} (${discount.name}) ${translate(
                        "isApplied"
                    )}`,
                    onlineCustomerMessage: discount.onlineCustomerMessage
                });
            } else {
                removeDiscount(translate("foundNoDiscount"));
            }
        } else {
            removeDiscount(translate("foundNoDiscount"));
        }
    };

    const removeDiscount = (msg: string) => {
        setActiveOrderWindowDiscount(null, []);
        setDiscountMessage({
            success: false,
            message: msg,
            onlineCustomerMessage: ""
        });
    };

    const handleEatingOptionClick = (eatingSelection: any) => {
        handleSetFoodOptions({
            deliveryInformation: null,
            cateringInformation: null,
            eatingPreference: eatingSelection,
            pickupOptions: {
                time: timeConstants.ASAP,
                date: moment().format("YYYY-MM-DD"),
                isEarliest: true
            }
        });
    };

    useEffect(() => {
        /** I know we have an event listener hook (didn't work for this) - */
        window.addEventListener("popstate", () => removeDiscount(""));
        return () => {
            window.removeEventListener("popstate", () => removeDiscount(""));
        };
    }, []);

    useEffect(() => {
        if (!!appliedDiscount && !!appliedDiscount?.code) {
            /** Discount input is from online - as we changed the logic increment etc. for online but not here
             * This re-validates the discount code when the cart product quantity changes
             */
            formValidateDiscountCode(appliedDiscount.code);
        }
    }, [cartProductQuantity]);

    const showEatingOptions =
        eatingOptions.filter(
            (option: string) => option === EatingPreference.EAT_HERE || option === EatingPreference.TAKE_AWAY
        ).length > 1;

    return (
        <ExpressOnlineCheckoutContainer>
            <ExpressOnlineWrapper>
                <Flex
                    height="auto"
                    direction={{ base: "column", lg: "row" }}
                    align="center"
                    justify="center"
                    width="100%"
                    padding={{ base: 4, lg: 8 }}
                >
                    {isCompanyLanding ? (
                        <ExpressBackButton onClick={() => history.go(-1)} />
                    ) : (
                        <RouterLink to={shopUrl}>
                            <ExpressBackButton onClick={() => removeDiscount("")} />
                        </RouterLink>
                    )}
                    <Flex direction="column" width="100%" height="auto">
                        <CheckoutFormCard height="min-content">
                            <Flex marginBottom={6} align="center" justify="space-between" height="auto">
                                <LanguageHeader tid="yourOrder" as="h2" size="2xl" m={0} />
                                <Flex align="center">
                                    {showEatingOptions && (
                                        <ExpressOnlinePickupButton
                                            handleSetFoodOptions={handleEatingOptionClick}
                                            isTakeAway={isTakeAway}
                                            eatingPreference={eatingPreference}
                                            eatingOptions={expressOnlineDisplaySettings.eatingOptions}
                                        />
                                    )}
                                </Flex>
                            </Flex>
                            <Flex maxHeight="calc(25vh - 2rem)" h="auto">
                                <OnlineCustomerCartList appliedDiscount={appliedDiscount} onCheckout={true} />
                            </Flex>
                            <NewDivider my={4} color="gray.400" />
                            {commentToClient && (
                                <>
                                    <Flex height="inherit" align="center" marginBottom={4}>
                                        <LanguageHeader tid="messageToCustomer" size="lg" margin="0" />
                                    </Flex>
                                    <Text fontSize="xl" color="gray.600">
                                        {commentToClient}
                                    </Text>
                                </>
                            )}
                            <Flex marginBottom={4} align="center" h="auto">
                                <LanguageHeader tid="messageToKitchen" size="lg" margin="0" />
                            </Flex>
                            <Field
                                placeholder={commentPlaceholder || "Allergier m.m."}
                                fullWidth
                                as={Textarea}
                                size="lg"
                                height="100px"
                                name="comment"
                                disabled={disableShopComments}
                                resize="none"
                            />
                            <NewDivider my={4} color="gray.400" />
                            <DiscountInput
                                appliedDiscount={appliedDiscount}
                                discountMessage={discountMessage}
                                handleCheckDiscount={formValidateDiscountCode}
                                onRemoveDiscount={() => {
                                    removeDiscount("");
                                }}
                            />
                            <List listStyle="none" stretch={4} mt={8}>
                                {appliedDiscount && (
                                    <>
                                        <ListItem display="flex" justifyContent="space-between">
                                            <OnlineText m="0">{translate("originalPrice")}.</OnlineText>
                                            <OnlineText>
                                                {formatSwedishFinancialNumbers(getCartTotalOriginalPrice(cartProducts))}
                                            </OnlineText>
                                        </ListItem>
                                        <ListItem display="flex" justifyContent="space-between">
                                            <OnlineText m="0" color="red.500" fontWeight="bold">
                                                {`${translate("discount")} (${appliedDiscount.name})`}
                                            </OnlineText>
                                            <OnlineText m="0" fontSize="lg" color="red.500" fontWeight="bold">
                                                {formatSwedishFinancialNumbers(
                                                    calculateOrderWindowTotalDiscount(cartProducts)
                                                )}
                                            </OnlineText>
                                        </ListItem>
                                    </>
                                )}
                                <ListItem display="flex" justifyContent="space-between">
                                    <OnlineText m="0">{translate("vat")}</OnlineText>
                                    <OnlineText m="0">{formatSwedishFinancialNumbers(vatAmount)}</OnlineText>
                                </ListItem>
                                <ListItem display="flex" justifyContent="space-between">
                                    <LanguageHeader tid="total" size="xl" m="0" />
                                    <Header as="h3" size="xl" m="0">
                                        {formatSwedishFinancialNumbers(totalPrice)}
                                    </Header>
                                </ListItem>
                            </List>
                            <Flex direction="column" mt={4}>
                                <Button
                                    onClick={() => paginate(1)}
                                    themeColor="blue"
                                    type="button"
                                    isDisabled={hasSwishDisrupted}
                                    fullWidth
                                    size="xl"
                                >
                                    {translate("continue")}
                                </Button>
                                {isSwishServiceDisrupted && <ExpressCheckoutDisruption />}
                            </Flex>
                        </CheckoutFormCard>
                    </Flex>
                </Flex>
            </ExpressOnlineWrapper>
        </ExpressOnlineCheckoutContainer>
    );
};
